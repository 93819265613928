<template>

 <v-app id="inspire">
    <nav>
    <v-toolbar>
     <v-toolbar-title class="headline text-uppercase">
     </v-toolbar-title>

                  <v-spacer></v-spacer>

            <img src="/img/logo.png" style="width: 164px;">

     <v-spacer></v-spacer>
    <div class="text-right mr-2">
              <v-btn outlined color="primary" @click="$router.push('/register')">Register</v-btn>
      </div>
   </v-toolbar>
 </nav>
    <v-main>
   

      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-card class="elevation-12">
              <v-toolbar
                color="primary"
                id="LoginToolbar"
              >
                <v-toolbar-title style="color: white;">Reset Password</v-toolbar-title>
                <div class="flex-grow-1"></div>

              </v-toolbar>
              <v-card-text>
                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation>
                  <v-text-field
                    ref="password"
                    v-model="password"
                    :rules="[() => !!password || 'This field is required']"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    placeholder="*********"
                    required
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                  />
                  <v-text-field
                    ref="confirm_password"
                    v-model="confirm_password"
                    :rules="[() => !!confirm_password  && confirm_password == password || 'Passwords must match']"
                    :append-icon="showConfirmPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showConfirmPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Confirm Password"
                    placeholder="*********"
                    required
                    @click:append="showConfirmPassword = !showConfirmPassword"
                  />
                  </v-form>
              </v-card-text>
              <v-card-actions>
                <div class="flex-grow-1"></div>
                <v-btn color="primary" @click="resetPassword">Reset Password</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
           
        <v-snackbar
        v-model="snackbar"
        :color="color"
        :top='true'
      >
        {{ snackbarMessage }}
        <v-btn
          dark

          @click="snackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<style>

</style>
<script>


export default {
  data: function () {
    return {
      password: '',
      confirm_password: '',
      snackbarMessage: 'Incorrect login info',
      snackbar: false,
      color: 'general',
      valid: '',
      showPassword: false,
      showConfirmPassword: false
           
    }
  },
 
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    resetPassword: function () {
      if (this.$refs.form.validate()) {
          this.$store.dispatch('resetPassword', { password: this.password, token: this.$route.query.token  })
            .then( response => {
              this.snackbarMessage = response.data
              this.snackbar = true
              // this.$router.go('/login')
            }

            )
            .catch(err => {
              this.snackbarMessage = err.response.data
              this.snackbar= true
            })
      }else {
        this.snackbarMessage = "Kindly fill in both fields"
        this.snackbar= true
      }
    },
    
  },
  metaInfo () {
    return {
      title: 'Cubixd | Reset Password'
    }
  }
}
</script>
